import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import ListRanking from "./ListRanking";
import data from "./MockData";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "list-ranking"
    }}>{`List Ranking`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={ListRanking} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <p>{`Structure defined for `}<strong parentName="p">{`data`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`[
  {
    company: 'State Farm',
    address: '123 Main St',
    score: '3.6',
    ratings: 17
  },
  {
    company: 'Geico',
    address: '123 Main St',
    score: '5',
    ratings: 24
  },
  {
    company: 'Todd\\'s Terrific Insurance',
    address: '123 Main St',
    score: '3.3',
    ratings: 12
  },
  {
    company: 'Dan\\'s Incredible Insurance',
    address: '123 Main St',
    score: '3.6',
    ratings: 35
  },
  {
    company: 'Nationwide',
    address: '123 Main St',
    score: '4.3',
    ratings: 21
  },
  {
    company: 'Allstate',
    address: '123 Main St',
    score: '5',
    ratings: 30
  },
]
`}</code></pre>
    <p>{`See below example to use the `}<strong parentName="p">{`data`}</strong>{` parameter on the component.`}</p>
    <Playground __position={1} __code={'<ListRanking\n  city=\"New York City\"\n  service=\"carInsurance\"\n  year=\"2020\"\n  displayCount={5}\n  data={data}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ListRanking,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ListRanking city="New York City" service="carInsurance" year="2020" displayCount={5} data={data} mdxType="ListRanking" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      